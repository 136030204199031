<template>
  <div class="content-wrapper">
    <h1 class="mb-5">Help Center Types</h1>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col lg="3">
          <b-form-select
            v-model="companyListSelected"
            :options="filterCompanyList"
            size="sm"
          ></b-form-select>
        </b-col>
        <b-col class="text-lg-right">
          <b-button size="sm" class="mb-2" v-b-toggle.sidebar-help-center-types>
            <b-icon icon="plus" aria-hidden="true"></b-icon> New Help Center
            Type
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay
      :show="items == null || loading.getData"
      rounded="sm"
      class="overlay-placeholder"
    >
      <b-card>
        <b-table
          hover
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <b-icon-gear-fill></b-icon-gear-fill>
              </template>
              <b-dropdown-item
                href="#"
                v-b-toggle.sidebar-help-center-types
                @click="
                  currentType = row.item;
                  tmpType = JSON.parse(JSON.stringify(row.item));
                "
                >Edit</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                v-if="row.item.attiva"
                @click="row.item.attiva = !row.item.attiva"
                >Block</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  currentType = row.item;
                  showDeleteModal = true;
                "
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>

          <template #cell(t_text)="row">
            {{ row.item.t_text[view_lang] }}
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mt-4 mb-1"
        ></b-pagination>
      </b-card>
    </b-overlay>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-help-center-types"
      right
      shadow
      lazy
      @hidden="resetData"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="tmpType && tmpType.text">
          {{ tmpType.text }}
        </h4>
        <h4 v-else>New Help Center Type</h4>
        <b-form-group label="Text">
          <b-form-input v-model="tmpType.t_text[view_lang]" />
          <LangOptions v-model="view_lang" :options="languages" />
        </b-form-group>

        <div class="mt-5 mb-5">
          <b-button
            squared
            variant="primary"
            size="lg"
            class="big-button"
            @click="saveItem"
            >Confirm</b-button
          >
        </div>
      </div>
    </b-sidebar>
    <b-modal
      v-model="showDeleteModal"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="resetData"
      @ok="deleteItem(currentType.value)"
      >Do you really want to delete the selected element?</b-modal
    >
  </div>
</template>

<script>
import AxiosService from "./../axiosServices/AxiosService.js";
import { theme } from "@/config/global";
import { mapGetters } from "vuex";
import { SET_CURRENT_COMPANY } from "../store/actions/config";

export default {
  name: "HelpCenterTypes",
  components: {},
  data() {
    return {
      loading: {
        getData: false,
      },

      items: [],

      fields: [
        { key: "actions", label: "Actions" },
        { key: "t_text", label: "Text" },
      ],
      view_lang: null,
      itemsLang: {
        attivita: null,
        mission: null,
        respCompiti: null,
        ruolo: null,
      },
      currentPage: 1,
      perPage: 10,
      filter: "",
      currentType: null,
      tmpType: {},
      showDeleteModal: false,

      companyList: [],
      companyListSelected: null,

      theme: "",
    };
  },
  service: null,
  created() {
    this.view_lang = this.default_lang.value || this.default_lang;
    this.tempItem = { ...this.modelItem };

    this.service = new AxiosService("Option/HelpDeskType");

    this.resetData();
    this.theme = theme;
    console.log(theme);
  },
  async mounted() {
    await this.service
      .getOptions("Company")
      .then((data) => (this.companyList = data));

    this.companyListSelected = this.$store.getters.currentCompany;

    this.getData();
  },
  methods: {
    getData() {
      this.loading.getData = true;
      this.items.splice(0);
      const service = new AxiosService(
        "Option/HelpDeskType/" + this.companyListSelected
      );

      service
        .read()
        .then((data) => {
          console.log("data", data);
          this.items = data;
          console.log("items", this.items);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.getData = false;
        });
    },
    resetData() {
      this.currentType = null;
      this.tmpType = {
        value: 0,
        text: "",
        id_company: null,
        t_text: {},
      };
      for (const property in this.itemsLang) {
        this.itemsLang[property] = this.default_lang.value;
      }
    },
    saveItem() {
      let item = { ...this.tmpType };
      item.id_company = this.companyListSelected;
      item.text = item.t_text[this.view_lang];

      if (item.value) {
        this.service
          .putCustomEndpoint(`Option/HelpDeskType/Save/${item.value}`, "", item)
          .then((response) => {
            if (response.value == item.value) {
              Object.keys(this.currentType).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentType[element] = item[element];
                }
              });
              this.$root.$emit(
                "bv::toggle::collapse",
                "sidebar-help-center-types"
              );
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      } else {
        this.service
          .postCustomEndpoint(
            `Option/HelpDeskType/Add/${item.id_company}`,
            "",
            item
          )
          .then((response) => {
            if (response.value) {
              item.value = response.value;
              this.items.push(item);
              this.$root.$emit(
                "bv::toggle::collapse",
                "sidebar-help-center-types"
              );
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      }
    },
    deleteItem(value) {
      this.service
        .putCustomEndpoint(`Option/HelpDeskType/Delete/${value}`, "", null)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => value !== currentItem.value
            );
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
  },
  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),
    totalRows() {
      return this.items.length;
    },
    filterCompanyList() {
      return [{ value: null, text: "All companies" }, ...this.companyList];
    },

    currentCompanyFromStore() {
      return this.$store.getters.currentCompany;
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
      },
    },
    companyListSelected: {
      handler() {
        this.$store.dispatch(SET_CURRENT_COMPANY, this.companyListSelected);
      },
    },
    currentCompanyFromStore: {
      handler() {
        this.companyListSelected = this.currentCompanyFromStore;
        this.getData();
      },
    },
  },
};
</script>
